export const state = () => ({
    showNewTask: false,
    type: null,
    assigneeID: null,
    projectID: null,
    entityID: null,
    dateStart: null,
    dateEnd: null,
    taskToOpen: null,
});

export const getters = {
    getShowNewTask(state) {
        return state.showNewTask;
    },
    getEntity(state) {
        return state.entityID;
    },
    getType(state) {
        return state.type;
    },
    getProject(state) {
        return state.projectID;
    },
    getAssignee(state) {
        return state.assigneeID;
    },
    getDateStart(state) {
        return state.dateStart;
    },
    getDateEnd(state) {
        return state.dateEnd;
    },
    getTaskToOpen(state) {
        return state.taskToOpen;
    }
};

export const mutations = {
    setShowNewTask(state, status) {
        state.showNewTask = status;
    },
    setType(state, type) {
        state.type = type;
    },
    setAssigneeID(state, assignee) {
        state.assigneeID = assignee;
    },
    setProjectID(state, project) {
        state.projectID = project;
    },
    setEntityID(state, entityId) {
        state.entityID = entityId;
    },
    setDateStart(state, date) {
        state.dateStart = date;
    },
    setDateEnd(state, date) {
        state.dateEnd = date;
    },
    resetTask(state) {
        state.entityID = null;
        state.typeID = null;
        state.projectID = null;
        state.assigneeID = null;
    },
    setTaskToOpen(state, payload) {
        state.taskToOpen = payload;
    },
};

export const actions = {
    setShowNewTask(context, payload) {
        context.commit('setType', payload.type || null);

        context.commit('setAssigneeID', payload.assignee?.id || payload.assignee || null);
        context.commit('setProjectID', payload.project?.id || payload.project || null);
        context.commit('setEntityID', payload.entity?.id || payload.entity || null);
        context.commit('setDateStart', payload.dateStart || null);
        context.commit('setDateEnd', payload.dateEnd || null);

        context.commit('setShowNewTask', payload.state);
    },
};
