export default async function ({ redirect, store }) {
    await store.dispatch('auth/initAuth');
    if (store.getters['auth/isAuthenticated']) {
        await store.dispatch('auth/validateToken')
            .then(() => {})
            .catch((e) => {
                if (e.response.status === 401) {
                    localStorage.removeItem('token');
                    store.dispatch('auth/clearTokens');

                }
            });
    } else {
        return redirect('/login');
    }
}
