<template>
    <div
        class="main"
        :class="{ 'sidebar-open': isProjectSidebarOpen, fullscreen: fullScreenState, mainClasses }"
        data-app
    >
        <main-header />

        <main-sidebar />

        <div class="main-content pt-14">
            <div class="content m-4 mt-0">
                <Nuxt />
            </div>
        </div>

        <notifications-wrapper />

        <!-- <base-notification /> -->

        <send-message :show-modal="getShowNewMessage" />

        <new-task :task-visibility="isNewTaskSidebarOpen" />

        <create-project-modal :show-modal="$store.state.project.isNewProjectModalOpen" />

        <div v-if="$config.node_env === 'development'" class="fixed bottom-5 right-16 z-50">
            <DarkModeToggler />
        </div>

        {{/* this makes sure that all the task status colors are generated */}}
        <div
            class="bg-system-blue/50 bg-gray-500/50 bg-system-purple/50 bg-success/50 text-system-blue/75 text-gray-500/75 text-system-purple/75 text-success/75"
        ></div>
    </div>
</template>

<script>
import uniqid from 'uniqid';
import { mapGetters } from 'vuex';
import MainHeader from '@/components/Layouts/Header/MainHeader.vue';
import MainSidebar from '@/components/Layouts/MainSidebar.vue';
import NotificationsWrapper from '~/components/UI/Notifications/NotificationsWrapper.vue';
import CreateProjectModal from '~/components/Projects/Create/CreateProjectModal.vue';
import DarkModeToggler from '@/components/UI/Development/DarkModeToggler';

export default {
    name: 'DefaultLayout',
    components: {
        MainHeader,
        MainSidebar,
        NotificationsWrapper,
        CreateProjectModal,
        DarkModeToggler,
    },
    // Auth should come first, permission second. The rest does not matter
    middleware: ['auth', 'permission', 'management', 'project'],
    data() {
        return {
            loadedOn: new Date(),
            socket: null,
            isFeedbackModalOpen: null,
            isTaskSidebarOpen: true,
            mainClasses: '',
        };
    },
    computed: {
        ...mapGetters({
            fullScreenState: 'getFullScreenState',
            getShowNewMessage: 'messages/getShowNewMessage',
            isProjectSidebarOpen: 'preferences/getSidebarState',
            isNewTaskSidebarOpen: 'tasks/getShowNewTask',
        }),
    },
    watch: {
        isNewTaskSidebarOpen(val) {
            if (val) {
                this.$lockScroll();
            } else {
                this.$unlockScroll();
            }
        },
    },
    created() {
        this.checkDayChange();
        setTimeout(this.checkToken, 1000);
        this.calculateScreenSizeCategory();
        this.$store.commit('users/me/setAxiosId', uniqid());
    },
    mounted() {
        window.onbeforeunload = this.checkOngoingProcessesBeforeClosing;

        window.addEventListener('resize', this.calculateScreenSizeCategory);

        if (window.navigator.userAgent.toLowerCase().includes('firefox')) {
            document.querySelector('html').classList.add('firefox');
            document.querySelector('body').classList.add('firefox');
        }

        this.socket = this.$nuxtSocket({
            name: process.env.node_env,
            auth: {
                apiToken: this.$store.state.auth.token,
            },
        });

        this.socket?.on('ev.event', this.handleEvents);

        this.socket?.on('connect_error', (err) => {
            if (err.message === 'xhr poll error') {
                console.log("Can't connect to WSS server");
            } else {
                console.log(err.message);
            }
            // this.$notify({ title: err.message, type: 'info' })
        });

        // For WSS debug
        this.$nuxt.$on('ev.event', this.handleEvents);
    },
    destroyed() {
        window.removeEventListener('resize', this.calculateScreenSizeCategory);
    },
    methods: {
        calculateScreenSizeCategory(e = null, width = 0) {
            if (!width) width = window.innerWidth;
            let sizeCategory = 0;
            switch (true) {
                case width > 1536: sizeCategory = 4; break;
                case width > 1280: sizeCategory = 3; break;
                case width > 1024: sizeCategory = 2; break;
                case width >  768: sizeCategory = 1; break;
            }
            this.$store.commit('users/me/setScreenSize', sizeCategory);
        },
        checkDayChange() {
            if (!this.$moment().isSame(this.$moment(this.loadedOn), 'day')) {
                window.location.reload();
            } else {
                setTimeout(this.checkDayChange, 1000);
            }
        },
        async checkToken() {
            if (this.$store.state.auth.token === null) {
                await this.$router.push('/login');
                return;
            }
            await this.$store.dispatch('auth/validateToken')
                .catch((e) => {
                    if (e.response?.status === 401) {
                        this.$store.dispatch('auth/clearTokens');
                        this.$router.push('/login');
                    }
                });
            if (this.$store.state.auth.token !== null) {
                setTimeout(() => this.checkToken(), 30000);
            }
        },
        checkOngoingProcessesBeforeClosing() {
            if (this.$store.state.users.me.unloadBlockers.length > 0) {
                return "Uploads in progress. Are you sure you wish to exit?";
            }
        },
        handleEvents(res) {
            if (!res?.type) {
                this.$notify({type: 'error', title: 'Invalid WSS event received!'});
                return;
            }

            // Filter out self-actions
            if (res?.type && res.addressee?.axiosId && res.addressee?.axiosId === this.$store.state.users.me.axiosId) {
                return;
            }

            const afJobReadyAlertAudio = new Audio('/audio/pussy-alert.mp3');
            const messageAudio = new Audio('/audio/pussy-message.mp3');

            switch (res.type) {
                case 'refresh.me':
                    this.$store.dispatch('users/me/setMe');
                    break;
                case 'message.new':
                    this.$notify({ group: 'message', text: res.payload, type: 'notification' });
                    this.$store.commit('messages/setUnreadMessages', true);
                    this.$store.commit('messages/setRefreshMessagesTable', true);
                    this.$store.dispatch('messages/fetchHeaderMessages');
                    messageAudio.play();
                    break;
                case 'notification.new':
                    this.$notify({ title: res.payload, type: 'notification' });
                    this.$store.commit('users/me/setNewNotification', true);
                    this.$store.dispatch('users/me/setMe');
                    this.$nuxt.$emit('refreshSystemLog');
                    break;
                case 'notification.update':
                    this.$store.dispatch('users/me/setMe');
                    this.$nuxt.$emit('refreshSystemLog');
                    break;
                case 'project.update':
                    this.$nuxt.$emit('refreshDashboard');
                    this.$store.dispatch('users/me/setMe');
                    if (this.$route.params.id === String(res.payload.id)) {
                        this.$store.dispatch('project/fetchActiveProject', {id: res.payload.id});
                    }
                    break;
                case 'sequence.new':
                    if (this.$route.params.id === String(res.payload.project.id)) {
                        this.$notify({type: 'notification', title: 'New group received!', text: 'Refreshing...'});
                        this.$store.dispatch('project/fetchSequences', {id: this.$route.params.id});
                    }
                    break;
                case 'shot.new':
                    if (this.$route.params.id === String(res.payload.sequence.project.id)) {
                        this.$notify({type: 'notification', title: 'New shot received!', text: 'Refreshing...'});
                        this.$store.dispatch('project/fetchSequences', {id: this.$route.params.id});
                    }
                    break;
                case 'shot.update':
                    if (this.$route.params.shot === String(res.payload.id)) {
                        this.$notify({type: 'notification', title: 'Shot updated!', text: 'Refreshing...'});
                        this.$store.dispatch('project/fetchActiveShot', {id: this.$route.params.shot});
                    }
                    break;
                case 'pass.new':
                    if (this.$route.params.shot === String(res.payload.shot.id)) {
                        this.$notify({type: 'notification', title: 'New pass received!', text: 'Refreshing...'});
                        if (this.$route.params.shotTab === String(res.payload.type.toLowerCase())) {
                            this.$nuxt.$emit('refreshPasses', res.payload.type);
                        } else {
                            this.$store.dispatch('project/fetchPasses', {
                                id: res.payload.shot.id,
                                type: res.payload.type,
                            });
                        }
                    }
                    break;
                case 'submit.new':
                    this.$notify({
                        type: 'notification',
                        data: 'html',
                        title: `${res.payload.pass.shot.sequence.project.name}: New submit`,
                        text: `${res.payload.pass.shot.sequence.name} / ${res.payload.pass.shot.name} / ` +
                            `${res.payload.pass.type} / ${res.payload.pass.name} / v${res.payload.version}` +
                            `<br>${res.payload.created_meta.fullName}`,
                    });
                    if (this.$route.params.id === String(res.payload.pass.shot.sequence.project.id)) {
                        this.$store.dispatch('project/fetchSequences', { id: this.$route.params.id });
                        if (this.$route.params.shot === String(res.payload.pass.shot.id)) {
                            this.$store.dispatch('project/fetchActiveShot', { id: this.$route.params.shot });
                            if (this.$route.params.shotTab.toLowerCase() === String(res.payload.pass.type).toLowerCase()) {
                                this.$nuxt.$emit('refreshPasses', res.payload.pass.type);
                            } else {
                                this.$store.dispatch('project/fetchPasses', {
                                    id: res.payload.pass.shot.id,
                                    type: res.payload.pass.type
                                });
                            }
                        }
                    }
                    break;
                case 'submit.update':
                    if (this.$route.params.shot === String(res.payload.pass.shot.id)) {
                        if (this.$route.params.shotTab.toLowerCase() === String(res.payload.pass.type).toLowerCase()) {
                            this.$nuxt.$emit('refreshPasses', res.payload.pass.type);
                        } else {
                            this.$store.dispatch('project/fetchPasses', {
                                id: res.payload.pass.shot.id,
                                type: res.payload.pass.type
                            });
                        }
                        if (this.$route.params.submit === String(res.payload.id)) {
                            this.$nuxt.$emit('refreshOpenSubmit');
                        }
                    }
                    break;
                case 'submit.upload_progress':
                    this.$nuxt.$emit('uploadProgress', res.payload);
                    break;
                case 'dispo_entry.new':
                    {
                        const start = this.$moment(res.payload.dateStart);
                        const end = this.$moment(res.payload.dateEnd);
                        const title = res.payload.outOfOffice || res.payload.task.name;
                        if (start.isSame(end, 'day')) {
                            this.$notify({
                                type: 'notification',
                                title: `Dispo entry received! (${start.format('MMM/DD')})`,
                                text: `${title}: ${start.format('HH:mm')} - ${end.format('HH:mm')}`
                            });
                        } else {
                            this.$notify({
                                type: 'notification',
                                title: `Dispo entry received! (${start.format('MMM/DD')} - ${end.format('MMM/DD')})`,
                                text: title,
                            });
                        }
                    }
                    this.$nuxt.$emit('refreshDispo');
                    messageAudio.play();
                    break;
                case 'dispo_entry.update':
                    this.$notify({
                        type: 'notification',
                        title: `Your dispo was updated!`,
                    });
                    this.$nuxt.$emit('refreshDispo');
                    messageAudio.play();
                    break;
                case 'daily.new':
                    this.$notify({
                        type: 'notification',
                        title: `${res.payload.project.name}: New daily`,
                        text: `${res.payload.submit.shot.name}, v${res.payload.clientVersion}`,
                    });
                    if (this.$route.params.id === String(res.payload.project.id)) {
                        this.$nuxt.$emit('refreshDailiesTable');
                        if (this.$route.params.daily === String(res.payload.id)) {
                            this.$nuxt.$emit('refreshOpenDaily');
                        }
                    }
                    afJobReadyAlertAudio.play();
                    break;
                case 'daily.update':
                    if (this.$route.params.id === String(res.payload.project.id)) {
                        this.$nuxt.$emit('refreshDailiesTable');
                        if (this.$route.params.daily === String(res.payload.id)) {
                            this.$nuxt.$emit('refreshOpenDaily');
                        }
                    }
                    afJobReadyAlertAudio.play();
                    break;
                case 'broadcast.new':
                    this.$notify({
                        type: 'notification',
                        title: `${res.payload.project.name}: New broadcast`,
                        text: `${res.payload.submit.shot.name}, ${res.payload.filmCode}`,
                    });
                    this.$nuxt.$emit('refreshBroadcastsTable', res.payload);
                    afJobReadyAlertAudio.play();
                    break;
                case 'broadcast.update':
                    this.$nuxt.$emit('refreshBroadcastsTable', res.payload);
                    afJobReadyAlertAudio.play();
                    break;
                case 'task.new':
                    this.$notify({
                        title: 'You have a new task!',
                        text: `${res.payload.project?.name} / ${res.payload.name}`,
                        type: 'notification'
                    });
                    this.$nuxt.$emit('refreshTasksTable', res.payload);
                    messageAudio.play();
                    break;
                case 'task.update':
                    this.$notify({
                        title: 'Your task has new updates!',
                        text: `${res.payload.project?.name}`,
                        type: 'notification'
                    });
                    this.$nuxt.$emit('refreshTasksTable', res.payload);
                    if (this.$route.params.task === String(res.payload.id)) {
                        this.$nuxt.$emit('refreshOpenTask');
                    }
                    messageAudio.play();
                    break;
                case 'download.ready':
                    this.$nuxt.$emit('fetchDownloads', res.payload.tabId);
                    this.$nuxt.$emit('startDownload', res.payload);
                    break;
                case 'feedback.new':
                    this.$notify({type: 'notification', title: 'New feedback received!'});
                    this.$nuxt.$emit('refreshSystemLog');
                    break;
                case 'note.shared':
                    this.$notify({
                        type: 'notification',
                        title: `${res.payload.owner.fullName}: Note shared`,
                        text: res.payload.name
                    });
                    this.$nuxt.$emit('refreshNotes');
                    break;
                case 'resource.new':
                    this.$notify({
                        type: 'notification',
                        title: `${res.payload.project.name}: New resource`,
                        text: `${res.payload.category.name} v${res.payload.version}`
                    });
                    this.$nuxt.$emit('refreshResourcesTable');
                    break;
                case 'archive.processed':
                    this.$nuxt.$emit('refreshProjectArchiveData');
                    break;
                default:
                    this.$notify({type: 'error', title: 'Unhandled WSS event received!', text: res.type});
                    break;
            }
        },
    }
};
</script>
