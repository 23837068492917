import _ from 'lodash';
import moment from 'moment';
import SparkMD5 from 'spark-md5';
import ChunkedFileReader from 'chunked-file-reader';

export default ({ app, store, $moment }, inject) => {
    const catchPost = (e) => {
        if (e?.response?.data?.errors) {
            for (const error of e.response.data.errors) {
                window.$nuxt.$notify({
                    title: e.response.data.message,
                    text: `${error.key}: ${error.message}`,
                    type: 'error'
                });
            }
        } else if (e.response?.status && e.response?.statusText) {
            const options = {
                title: `Error ${e.response.status}: ${e.response.statusText}`,
                type: 'error'
            };
            if (e.response?.data?.message) {
                options.text = e.response.data.message;
            }
            window.$nuxt.$notify(options);
        } else {
            console.log({error: e});
            window.$nuxt.$notify({
                title: `Unknown error occurred`,
                text: 'Please contact and report to developer',
                type: 'error'
            });
        }
    };
    const catchGetFatal = (e) => {
        if (e?.response?.data?.message) {
            const error = new Error(e.response.data.message);
            error.statusCode = e.response?.status || 500;
            throw error;
        } else if (e?.response?.status && e?.response?.statusText) {
            const error = new Error(`Error ${e.response.status}: ${e.response.statusText}`);
            error.statusCode = e.response?.status || 500;
            throw error;
        } else {
            console.log({error: e});
            const error = new Error(`Unknown error occurred`);
            error.statusCode = e.response?.status || 500;
            throw error;
        }
    };

    inject('clone', (item) => {
        return JSON.parse(JSON.stringify(item));
    });
    inject('difference', (obj1, obj2) => {
        const object1 = app.$clone(obj1);
        const object2 = app.$clone(obj2);
        const allKeys = _.union(_.keys(object1), _.keys(object2));

        return _.reduce(
            allKeys,
            (result, key) => {
                if (typeof object1[key] !== typeof object2[key]) {
                    if (typeof object2[key] === 'number' || object2[key] === null) {
                        if (object1[key] === '') {
                            object1[key] = null;
                        } else {
                            object1[key] = isNaN(+object1[key]) ? object1[key] : +object1[key];
                        }
                    }
                }
                if (!_.isEqual(object1[key], object2[key])) {
                    result[key] = object1[key];
                }

                return result;
            },
            {}
        );
    });
    inject('keyedGet', (obj, key) => {
        let keyParts = key.split('.');
        let current = obj;
        while(keyParts.length > 0 && current !== null && (typeof current === 'object')) {
            current = current[keyParts[0]];
            keyParts = keyParts.slice(1);
        }
        return current;
    });
    inject('keyedSet', (obj, key, value) => {
        let keyParts = key.split('.');
        let current = obj;
        while(keyParts.length > 1 && current !== null) {
            if (Object.keys(current).includes(keyParts[0])) {
                current = current[keyParts[0]];
            } else {
                current[keyParts[0]] = {};
                current = current[keyParts[0]];
            }
            if (typeof current !== 'object') {
                window.$nuxt.$notify({
                    title: `Failed to set key "${key}", object had non-object property on path!"`,
                    type: 'error'
                });
            }
            keyParts = keyParts.slice(1);
        }
        current[keyParts[0]] = value;
    });
    inject('deepDiff', (obj1, obj2) => {
        if ((typeof obj1 !== 'object') || (typeof obj2 !== 'object')) {
            return obj1 !== obj2 ? [obj1, obj2] : false;
        }
        const result = {};
        let allKeys = _.union(_.keys(obj1), _.keys(obj2));
        while(allKeys.length > 0) {
            const current = allKeys.pop();
            const o1 = app.$keyedGet(obj1, current);
            const o2 = app.$keyedGet(obj2, current);
            if ((typeof o1 !== 'object') || (typeof o2 !== 'object')) {
                if (o1 !== o2) {
                    app.$keyedSet(result, current, [o1, o2]);
                }
            } else {
                allKeys = [...allKeys, ..._.union(_.keys(o1), _.keys(o2)).map(x => `${current}.${x}`)];
            }
        }
        return result;
    });
    inject('toISOString', (date) => {
        if (date !== null) {
            return moment(date).toISOString();
        } else {
            return date;
        }
    });
    inject('toISO', (date) => {
        // default ISO 8601
        if (date !== null) {
            return moment(date).format();
        } else {
            return date;
        }
    });
    inject('toISOMinusHour', (date) => {
        // default ISO 8601
        if (date !== null) {
            return moment(date).subtract(1, 'hours').format();
        } else {
            return date;
        }
    });
    inject('toISOwithoutTime', (date) => {
        // default ISO 8601
        if (date !== null) {
            return moment(date).format('YYYY-MM-DD');
        } else {
            return date;
        }
    });
    inject('folderPath', (path) => {
        let mountPath = store.state.config.config?.config?.mountPath;
        if (!mountPath) return;
        if (mountPath.charAt(mountPath.length - 1) === '/') {
            mountPath = mountPath.slice(0,-1);
        }
        return `pussy://${mountPath}/${path}`;
    });
    inject('formatFileSize', (bytes, decimals = 2) => {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    });
    inject('checkPermission', (payload) => {
        if (Array.isArray(payload)) {
            return payload.every((key) => {
                return (
                    app.store.state.users.me.permissions &&
                    !!app.store.state.users.me.permissions.find((data) => data.handle === key)
                );
            });
        }
        return (
            app.store.state.users.me.permissions &&
            !!app.store.state.users.me.permissions.find((data) => data.handle === payload)
        );
    });
    inject('lockScroll', () => {
        // disable body scroll would've been better, but it doesn't work here for some reason
        document.querySelector('html').style.overflow = 'hidden';
    });
    inject('unlockScroll', () => {
        document.querySelector('html').style.overflow = '';
    });
    inject('copyToClipboard', (link) => {
        const dummy = document.createElement('input');
        document.body.appendChild(dummy);
        dummy.value = link;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
    });
    inject('fileMD5', async (file) => {
        const size = file.size;
        while (!store.getters['users/me/getCanHashNewFile']) {
            await new Promise(resolve => setTimeout(resolve, 200));
        }
        store.commit('users/me/addHashInProgress', size);

        return new Promise((resolve, reject) => {
            const spark  = new SparkMD5.ArrayBuffer();
            const reader = new ChunkedFileReader();

            reader.subscribe('chunk', (e) => {
                spark.append(e.chunk);
            });
            reader.subscribe('end', (e) => {
                const hash = spark.end(false);
                store.commit('users/me/removeHashInProgress', size);
                resolve(hash);
            });
            reader.readChunks(file);
        });
    });
    inject('downloadFile', (url) => {
        return app
            .$axios({
                url,
                method: 'GET',
                responseType: 'blob',
            })
            .then((res) => {
                const contentDispositionHeader = res.headers['content-disposition'];
                const filename = contentDispositionHeader.split(';')[1].split('=')[1].replaceAll('"', '');
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const fakeLink = document.createElement('a');
                fakeLink.href = downloadUrl;
                fakeLink.setAttribute('download', filename);
                fakeLink.setAttribute('target', '_self');
                document.body.appendChild(fakeLink);
                fakeLink.click();
                fakeLink.remove();
            });
    });
    inject('downloadAudioFile', (url) => {
        const fakeLink = document.createElement('a');
        fakeLink.href = url;
        fakeLink.setAttribute('download', url);
        fakeLink.setAttribute('target', '_self');
        document.body.appendChild(fakeLink);
        fakeLink.click();
        fakeLink.remove();
    });
    inject('formatErrorMessages', (errors) => {
        return errors.reduce((acc, curr) => {
            acc[curr.key] = curr.message;
            return acc;
        }, {});
    });
    inject('getDirectorySizeColorCode', (size) => {
        if (size >= 200 * 1024 * 1024) {
            return '#EC2616';
        } else if (size >= 100 * 1024 * 1024) {
            return '#F8981D';
        } else {
            return '#2AAF4D';
        }
    });
    inject('getAfanasyJobStatus', (afJob) => {
        if (!afJob) {
            return {
                status: null,
                label: '-',
                color: '#6A7184',
            };
        }

        if (afJob.progressState === 'DON') {
            return {
                status: 'done',
                label: 'Done',
                color: '#2AAF4D',
            };
        }

        if (afJob.progressState === 'ERR') {
            return {
                status: 'failed',
                label: 'Failed on farm',
                color: '#E02616',
            };
        }

        return {
            status: 'on_farm',
            label: 'On farm',
            color: '#1C44D7',
        };
    });
    inject('getMessageTypesFilteredByUserPermission', () => {
        return store.state.config.config.messages.types.filter((x) => {
            return !(
                (x.id !== 1 && !app.$checkPermission('project.read_list')) ||
                (x.id === 3 && !app.$checkPermission('bid.read_list')) ||
                (x.id === 4 && !app.$checkPermission('resource.read_list')) ||
                (x.id === 5 && !app.$checkPermission('shot.read_list')) ||
                (x.id === 6 && !app.$checkPermission('daily.read_list')) ||
                (x.id === 7 && !app.$checkPermission('broadcast.read_list')) ||
                (x.id === 8 && !app.$checkPermission('submit.read_list'))
            );
        });
    });
    inject('getTaskTypesFilteredByUserPermission', () => {
        return store.state.config.config.dispo.task_types.filter((x) => {
            return !(
                (x.id !== 1 && !app.$checkPermission('project.read_list')) ||
                (x.id === 3 && !app.$checkPermission('bid.read_list')) ||
                (x.id === 4 && !app.$checkPermission('resource.read_list')) ||
                (x.id === 5 && !app.$checkPermission('shot.read_list')) ||
                (x.id === 6 && !app.$checkPermission('daily.read_list')) ||
                (x.id === 7 && !app.$checkPermission('broadcast.read_list')) ||
                (x.id === 8 && !app.$checkPermission('submit.read_list'))
            );
        });
    });
    inject('getTaskStatusColor', (status) => {
        // in case you update a color, go to the default layout template and change it there too
        if (status === 1) {
            return {
                bg: 'bg-gray-500/50',
                text: 'text-gray-500/75',
            };
        } else if (status === 2) {
            return {
                bg: 'bg-system-blue/50',
                text: 'text-system-blue/75',
            };
        } else if (status === 3) {
            return {
                bg: 'bg-system-purple/50',
                text: 'text-system-purple/75',
            };
        } else {
            return {
                bg: 'bg-success/50',
                text: 'text-success/75',
            };
        }
    });
    inject('getProjectStatusColor', (status) => {
        // in case of color change, update values in vuetables.pcss too
        if (status === 'preproduction') {
            return {
                bg: 'bg-system-orange/50',
            };
        } else if (status === 'in_progress') {
            return {
                bg: 'bg-system-blue/50',
            };
        } else if (status === 'closed') {
            return {
                bg: 'bg-system-purple/50',
            };
        } else if (status === 'canceled') {
            return {
                bg: 'bg-gray-400/50',
            };
        } else if (status === 'ready_to_archive') {
            return {
                bg: 'bg-success/50',
            };
        } else if (status === 'archived' || status === 'restoration_in_progress') {
            return {
                bg: 'bg-dark-900/75',
            };
        } else if (status && status.includes('on_hold')) {
            return {
                bg: 'bg-gray-400/50',
            }
        } else {
            return {
                bg: '',
            };
        }
    });
    inject('getPossibleTaskStatusesToUpdateTaskTo', (task) => {
        if (!task) {
            return [];
        }
        const taskStatuses = store.state.config.config.dispo.task_statuses;
        const me = store.state.users.me.me;
        const taskStatusId = typeof task.status === 'number' ? task.status : task.status.id;

        let result;

        if (me.roles.includes('ROLE_ADMIN')) {
            // Admin
            result = taskStatuses;

        } else if (task.created_meta && me.id === task.created_meta.id) {
            // Approver (the one who created the task)
         if (taskStatusId === 1) {
                // Waiting task
                result = taskStatuses.filter((x) => [1, 4].includes(x.id));
            } else if (taskStatusId === 2) {
                // In progress
                result = taskStatuses.filter((x) => [1, 2].includes(x.id));
            }  else if (taskStatusId === 3) {
                // Review
                result = taskStatuses;
            } else if (taskStatusId === 4) {
                // Completed
                result = taskStatuses.filter((x) => [1, 4].includes(x.id));
            }
        } else {
            // Assignee
            // eslint-disable-next-line no-lonely-if
            if (taskStatusId === 1) {
                // Waiting
                result = taskStatuses.filter((x) => [1, 2].includes(x.id));
            } else if (taskStatusId === 2) {
                // In Progress
                result = taskStatuses.filter((x) => [1, 2, 3].includes(x.id));
            } else if (taskStatusId === 3) {
                // Review
                result = taskStatuses.filter((x) => [2, 3].includes(x.id));
            } else if (taskStatusId === 4) {
                // Completed
                result = taskStatuses.filter((x) => [4].includes(x.id));
            }
        }

        return result || [];
    });
    inject('projectIsBeforeArchived', (project) => {
        if (!project) {
            project = store.state.project.activeProject;
        }
        if (!project) {
            return false;
        }
        return project.status === 'preproduction' || project.status === 'in_progress';
    });
    inject('setPreference', (key, value) => {
        if (key.includes('_')) {
            const error = new Error("Preference key cannot contain underscores!");
            error.statusCode = 500;
            throw error;
        }
        const postData = new FormData();
        postData.append(key, value);
        return app.$axios.$post(`${process.env.prodAPI}/me/preferences`, postData)
            .catch(catchPost);
    });
    inject('passType', (id) => {
        if (id) {
            const types = store.state.config.config.passes?.types || [{ id: 'Comp' }, { id: 'CG' }, { id: 'Image' }, { id: 'Audio' }];
            const type = types.find(x => x.id.toLowerCase() === id.toLowerCase());
            return type?.id || id;
        }
        return id;
    });
    inject('catchPost', catchPost);
    inject('catchGetFatal', catchGetFatal);
};
