var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
            (_vm.submit && _vm.submit.afJob) ||
            (_vm.submit && !_vm.submit.afJob && _vm.submit.internalMovFile) ||
            (_vm.daily && _vm.daily.afJob)
        )?_c('div',{staticClass:"plyr-font-mono",class:("baseVideoPlayer-" + _vm._uid + " " + ((_vm.isVertical ? 'vertical' : '')))},[(
                _vm.progressState === 'DON' ||
                (_vm.submit && !_vm.submit.afJob && _vm.submit.internalMovFile)
            )?_c('vue-plyr',{ref:"videoPlayer",attrs:{"options":_vm.options}},[_c('video',{attrs:{"controls":"","crossorigin":"","playsinline":""}},[_c('source',{attrs:{"src":_vm.src,"type":"video/mp4"}})])]):_c('div',{staticClass:"flex items-center justify-center h-50"},[_c('i',{staticClass:"animate-spin las la-circle-notch mr-2"}),_vm._v("\n            Waiting for farm...\n        ")])],1):_c('div')])}
var staticRenderFns = []

export { render, staticRenderFns }