export const state = () => ({
    token: null,
    errors: '',
    fromPath: null,
});

export const getters = {
    isAuthenticated(state) {
        return state.token != null;
    },
    getErrors(state) {
        return state.errors;
    },
    getFromPath(state) {
        return state.fromPath;
    },
};

export const mutations = {
    setToken(state, token) {
        state.token = token;
    },
    clearToken(state) {
        state.token = null;
    },
    setErrors(state, errors) {
        state.errors = errors;
    },
    setFromPath(state, fromPath) {
        state.fromPath = fromPath;
    },
};

export const actions = {
    initAuth(vuexContext) {
        vuexContext.commit('setToken', localStorage.getItem('token'));
    },
    validateToken(vuexContext) {
        const token = vuexContext.state.token || localStorage.getItem('token');
        return this.$axios.$get(`${process.env.prodAPI}/validate-token/${token}`);
    },
    logout(vuexContext) {
        this.$axios
            .$post(`${process.env.prodAPI}/logout`)
            .then(() => {
                vuexContext.dispatch('clearTokens');
                this.$router.push('/login');
            })
            .catch(this.$catchPost);
    },
    clearTokens(vuexContext) {
        vuexContext.commit('clearToken');
        vuexContext.commit('clearToken');
    },
};
