export default async function ({ redirect, store }) {
    await store.dispatch('auth/initAuth');
    if (store.getters['auth/isAuthenticated']) {
        await store.dispatch('auth/validateToken')
            .then(() => {
                redirect('/');
            })
            .catch(() => {
                localStorage.removeItem('token');
                store.dispatch('auth/clearTokens');
            });
    }
}
