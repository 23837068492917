export const state = () => ({
    isShotDetailsPanelOpen: false,
});

export const mutations = {
    setIsShotDetailsPanelOpen(state, payload) {
        state.isShotDetailsPanelOpen = payload;
    },
    toggleIsShotDetailsPanelOpen(state) {
        state.isShotDetailsPanelOpen = !state.isShotDetailsPanelOpen;
    },
};
