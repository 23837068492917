<template>
    <div class="wrapper user-group relative">
        <div
            v-if="shown"
            class="fixed w-max h-max left-0 top-0 z-20"
            @click="shown = false"
        />
        <div
            class="flex items-center cursor-pointer"
            @click="() => { shown = !shown }"
        >
            <user-badge v-if="users[0]" :user="users[0]" icon disable-tooltip class="mr-2" />
            <span v-if="users.length > 1" class="text-xs"> + {{ users.length - 1 }}</span>
        </div>
        <div
            class="dropdown bg-white py-2 rounded-md absolute bottom-0 translate-y-full z-10 shadow-lg max-h-80 overflow-scroll"
            :class="{'-right-4': stickToRight, '-left-4': !stickToRight, 'shown': shown}"
        >
            <div v-for="(user, i) in users" :key="`user-${i}`" class="px-4 py-1">
                <user-badge :user="user" full-name disable-tooltip />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserGroupWithDropdown',
    props: {
        users: {
            type: Array,
            required: true,
        },
        stickToRight: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            shown: false,
        }
    }
};
</script>

<style scoped>
.wrapper:hover {
    .dropdown {
        opacity: 1;
        pointer-events: all;
    }
}
.dropdown {
    pointer-events: none;
    transition: opacity 0.3s;
    &::after {
        content: '';
        position: absolute;
        display: block;
        top: -1rem;
        left: 0;
        width: 100%;
        height: 2rem;
    }
    &:not(.shown) {
        opacity: 0;
    }
}
</style>
