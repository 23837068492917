<template>
    <vc-date-picker
        v-model="datePickerModel"
        is24hr
        :model-config="{ type: 'date', mask: 'iso' }"
        :is-dark="$colorMode.value === 'dark'"
        :mode="mode"
        :masks="{
            inputDateTime24hr: 'YYYY. MMM. D. HH:mm',
            input: 'YYYY. MMM D.',
        }"
        :valid-hours="{ min: 0, max: 24 }"
        :minute-increment="15"
        :disabled-dates="disabledDates"
        :popover="{ positionFixed: true, visibility: 'hover' }"
        @input="watchDate($event)"
    >
        <template #default="{ inputValue, togglePopover }">
            <div class="relative">
                <v-text-field
                    :value="inputValue"
                    :placeholder="placeholder"
                    prepend-inner-icon="las la-calendar"
                    solo
                    dense
                    hide-details
                    readonly
                    :disabled="disabled"
                    @click="togglePopover({ isInteractive: true })"
                />
                <transition v-if="clearable && !disabled" name="fade">
                    <button
                        v-if="inputValue !== ''"
                        type="button"
                        class="absolute right-0 top-0 h-10 w-10 rounded inline-flex justify-center items-center dark:bg-dark-800"
                        @click="
                            $emit('updateDate', '');
                            clearValue();
                        "
                    >
                        <i class="las la-times text-red bg-gray-200 dark:bg-dark-800" />
                    </button>
                </transition>
                <transition name="fade">
                    <div v-if="showError">
                        <p class="text-xs px-3 mt-1 text-red/50">{{ errorMessage }}</p>
                    </div>
                </transition>
            </div>
        </template>
    </vc-date-picker>
</template>

<script>
// USE: <date-picker :date="DATA" @updateDate="(e) => DATA = e" />
export default {
    props: {
        date: { type: [String, Date, Number], required: false, default: '' },
        required: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
        showModal: { type: Boolean, required: false, default: false },
        clearable: { type: Boolean, required: false, default: true },
        mode: { type: String, required: false, default: 'date' },
        disabledDates: { type: Object, required: false, default: null },
        placeholder: { type: String, required: false, default: 'Choose a date' },
        errorMessage: { type: String, required: false, default: 'Date can not be empty' },
    },
    data() {
        return {
            showError: false,
        };
    },
    computed: {
        datePickerModel: {
            get() {
                return this.date;
            },
            set(e) {
                this.$emit('updateDate', e);
            },
        },
    },
    watch: {
        showModal(e) {
            if (e === true) {
                this.showError = false;
            }
        },
    },
    methods: {
        clearValue() {
            if (this.required) {
                this.showError = true;
            }
        },
        watchDate(inputValue) {
            if (this.required) {
                if (inputValue) {
                    this.showError = false;
                } else {
                    this.showError = true;
                }
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.las {
    @apply text-xs !important;
}
</style>
