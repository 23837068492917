import moment from 'moment';

const getDefaultState = () => ({
    me: {},
    projects: [],
    formattedProjects: [],
    notifications: [],
    newNotification: false,
    permissions: null,
    unloadBlockers: [],
    hashesInProgress: 0,
    hashSize: 0,
    screenSize: 0,
    preferences: {},
    entityActions: [],
    axiosId: null,
});

export const state = () => getDefaultState();

export const getters = {
    getMe(state) {
        return state.me;
    },
    getActiveProjectSubscriptions(state) {
        return state.projects.filter((sub) => {
            const project = sub.project;
            if (!project) return false;
            if (project.status === 'preproduction') return true;
            if (project.status === 'in_progress') {
                if (moment(project.startDate).isAfter(moment(), 'day')) return false;
                if (moment(project.deadline).isBefore(moment(), 'day')) return false;
                if (!sub.subscribedUntil) return true;
                if (moment(sub.subscribedUntil).isSameOrAfter(moment(), 'day')) return true;
            }
            return false;
        });
    },
    getFormattedProjects(state) {
        return state.formattedProjects;
    },
    getNotifications(state) {
        return state.notifications;
    },
    getNewNotification(state) {
        return state.newNotification;
    },
    getPermissions(state) {
        return state.permissions;
    },
    getUnloadBlockers(state) {
        return state.unloadBlockers;
    },
    getScreenSize(state) {
        return state.screenSize;
    },
    getCanHashNewFile(state) {
        return state.hashSize < 256 * Math.pow(2, 20) && state.hashesInProgress < 500;
    },
    getPreferences(state) {
        return state.preferences;
    },
};

export const mutations = {
    setMe(state, me) {
        state.me = me;
    },
    setProjects(state, projects) {
        state.projects = projects;
    },
    setProjectsFormatted(state, projects) {
        for (const submission of projects) {
            if (state.formattedProjects.find((x) => x.id === submission.project.id)) {
                return;
            }

            state.formattedProjects.push(submission.project);
        }
    },
    deleteProject(state, projectID) {
        state.projects = state.projects.filter((data) => data.project.id !== projectID);
    },
    setNotifications(state, notifications) {
        state.notifications = notifications;
    },
    setNewNotification(state, newNotification) {
        state.newNotification = newNotification;
    },
    setPermissions(state, permissions) {
        state.permissions = permissions;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
        // don't replace this with state = getDefaultState()
        // https://github.com/vuejs/vuex/issues/1118
    },
    addUnloadBlocker(state, item) {
        state.unloadBlockers.push(item);
    },
    removeUnloadBlocker(state, item) {
        state.unloadBlockers = state.unloadBlockers.filter((x) => x !== item);
    },
    setScreenSize(state, size) {
        state.screenSize = size;
    },
    toggleFavoriteUser(state, payload) {
        if (state.me.favoriteUsers.includes(payload.id)) {
            state.me.favoriteUsers = state.me.favoriteUsers.filter((x) => x !== payload.id);
        } else {
            state.me.favoriteUsers.push(payload.id);
        }
        const postData = new FormData();
        if (state.me.favoriteUsers.length > 0) {
            state.me.favoriteUsers.forEach((user) => {
                postData.append('favoriteUsers[]', user);
            });
        } else {
            postData.append('favoriteUsers[]', []);
        }
        this.$axios
            .$post(`${process.env.prodAPI}/me/favorite-users`, postData)
            .then(() => {
                payload.api.refetchResources();
            })
            .catch(this.$catchPost);
    },
    addHashInProgress(state, size) {
        state.hashesInProgress++;
        state.hashSize += size;
    },
    removeHashInProgress(state, size) {
        state.hashesInProgress--;
        state.hashSize -= size;
    },
    setPreferences(state, payload) {
        state.preferences = payload;
    },
    setAxiosId(state, payload) {
        state.axiosId = payload;
    }
};

export const actions = {
    reset({ commit }) {
        commit('resetState');
    },
    async setMe(context) {
        const bufferedRoles = context.state.me.roles;
        return await this.$axios
            .$get(`${process.env.prodAPI}/me?scopes[]=with_project_subscriptions&scopes[]=with_favorite_users`)
            .then((res) => {
                for ( const role of res.result.user.roles) {
                    if (bufferedRoles && !bufferedRoles.includes(role)) {
                        window.location.reload();
                        break;
                    }
                }
                context.commit('setMe', res.result.user);
                context.commit('setPermissions', res.result.grantedPermissions);
                context.commit('setProjects', res.result.user.projectSubscriptions || []);
                context.commit('setProjectsFormatted', res.result.user.projectSubscriptions || []);
                context.commit('setNotifications', res.result.recentNotifications);
                context.commit('setPreferences', res.result.preferences);
            })
            .catch(this.$catchGetFatal);
    },
    async setMyProjects(context) {
        await this.$axios
            .$get(`${process.env.prodAPI}/me?scopes[]=with_project_subscriptions`)
            .then((res) => {
                context.commit(
                    'setProjects',
                    res.result.user.projectSubscriptions ? res.result.user.projectSubscriptions : []
                );
                context.commit(
                    'setProjectsFormatted',
                    res.result.user.projectSubscriptions ? res.result.user.projectSubscriptions : []
                );
            })
            .catch(this.$catchGetFatal);
    },
};
